<div (click)="focus()" [@focusClose]="state" (@focusClose.done)="activateButtons()" class="keypad" [ngClass]="entryResult">
    <div *ngIf="!clickableButtons" style="padding: 0.5em">
        <div>7 8 9</div>
        <div>4 5 6</div>
        <div>1 2 3</div>
        <div>&nbsp;&nbsp;0&nbsp;&nbsp;</div>
    </div>
    <div *ngIf="clickableButtons">
        <div class="numberButtons">
            <div>
                <button (click)="press(7)">7</button>
                <button (click)="press(8)">8</button>
                <button (click)="press(9)">9</button>
            </div>
            <div>
                <button (click)="press(4)">4</button>
                <button (click)="press(5)">5</button>
                <button (click)="press(6)">6</button>
            </div>
            <div>
                <button (click)="press(1)">1</button>
                <button (click)="press(2)">2</button>
                <button (click)="press(3)">3</button>
            </div>     
            <div>
                <button style="visibility: hidden;">0</button>
                <button (click)="press(0)">0</button>
            </div>       
        </div>
        <div class="screen">
            {{input}}
        </div>
    </div>
</div>
<button class="closeButton" *ngIf="state === 'focus'" (click)="close()">X</button>

